import { JobScaleEnum } from "@/generated/graphql-hooks";

export const jobScales = {
  [JobScaleEnum.WithinOneDay]: "1天內",
  [JobScaleEnum.OneToThreeDays]: "1-3天",
  [JobScaleEnum.WithinOneWeek]: "1個星期內",
  [JobScaleEnum.WithinTwoWeeks]: "2個星期內",
  [JobScaleEnum.WithinThreeWeeks]: "3個星期內",
  [JobScaleEnum.WithinOneMonth]: "1個月內",
  [JobScaleEnum.OneToThreeMonths]: "1-3個月",
};
