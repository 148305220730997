import { JobTypeEnum } from "@/generated/graphql-hooks";
import AdFilmIcon from "@/icons/job/ad_film.svg";
import FilledAdFilmIcon from "@/icons/job/ad_film_filled.svg";
import AdPhotoIcon from "@/icons/job/ad_photo.svg";
import FilledAdPhotoIcon from "@/icons/job/ad_photo_filled.svg";
import ExhibitionIcon from "@/icons/job/exhibition.svg";
import IgPostIcon from "@/icons/job/ig_post.svg";
import FilledIgPostIcon from "@/icons/job/ig_post_filled.svg";
import LiveStreamingIcon from "@/icons/job/live_streaming.svg";
import MicrofilmIcon from "@/icons/job/microfilm.svg";
import OthersIcon from "@/icons/job/others.svg";
import OutdoorExhibitionIcon from "@/icons/job/outdoor_exhibition.svg";
import ProductTrialIcon from "@/icons/job/product_trial.svg";
import FilledProductTrialIcon from "@/icons/job/product_trial_filled.svg";
import RestaurantIcon from "@/icons/job/restaurant.svg";
import FilledRestaurantIcon from "@/icons/job/restaurant_filled.svg";
import TreatmentIcon from "@/icons/job/treatment.svg";
import FilledTreatmentIcon from "@/icons/job/treatment_filled.svg";

export const jobTypes = {
  [JobTypeEnum.IgPost]: "IG貼文",
  [JobTypeEnum.AdFilm]: "宣傳影片",
  [JobTypeEnum.AdPhoto]: "平面模特兒",
  [JobTypeEnum.ProductTrial]: "產品試用",
  [JobTypeEnum.Restaurant]: "餐廳試食",
  [JobTypeEnum.Treatment]: "療程體驗",
  [JobTypeEnum.OutdoorExhibition]: "場地體驗",
  [JobTypeEnum.Exhibition]: "駐場活動",
  [JobTypeEnum.Microfilm]: "微電影",
  [JobTypeEnum.LiveStreaming]: "直播",
  [JobTypeEnum.Others]: "其他",
};

export const jobTypeIcons = {
  [JobTypeEnum.IgPost]: IgPostIcon,
  [JobTypeEnum.AdFilm]: AdFilmIcon,
  [JobTypeEnum.AdPhoto]: AdPhotoIcon,
  [JobTypeEnum.ProductTrial]: ProductTrialIcon,
  [JobTypeEnum.Restaurant]: RestaurantIcon,
  [JobTypeEnum.Treatment]: TreatmentIcon,
  [JobTypeEnum.OutdoorExhibition]: OutdoorExhibitionIcon,
  [JobTypeEnum.Exhibition]: ExhibitionIcon,
  [JobTypeEnum.Microfilm]: MicrofilmIcon,
  [JobTypeEnum.LiveStreaming]: LiveStreamingIcon,
  [JobTypeEnum.Others]: OthersIcon,
};

export const jobTypeFilledIcons = {
  [JobTypeEnum.IgPost]: FilledIgPostIcon,
  [JobTypeEnum.AdFilm]: FilledAdFilmIcon,
  [JobTypeEnum.ProductTrial]: FilledProductTrialIcon,
  [JobTypeEnum.AdPhoto]: FilledAdPhotoIcon,
  [JobTypeEnum.Restaurant]: FilledRestaurantIcon,
  [JobTypeEnum.Treatment]: FilledTreatmentIcon,
};
