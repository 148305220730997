import { QueryKey, useQueryClient } from "react-query";

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = (refetchKey: QueryKey) => {
    if (Array.isArray(refetchKey) && refetchKey.length) {
      refetchKey.forEach((key) => {
        queryClient.invalidateQueries(key);
      });
    } else {
      queryClient.invalidateQueries(refetchKey);
    }
  };

  return { invalidateQueries };
};
