import clsx from "clsx";
import { HTMLProps, forwardRef } from "react";

type ButtonProps = HTMLProps<HTMLButtonElement>;

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        className={clsx(
          "text-primary hover:opacity-70 focus:outline-none focus-visible:outline-none disabled:cursor-not-allowed",
          className,
        )}
        type="button"
      >
        {children}
      </button>
    );
  },
);
